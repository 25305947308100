<!--
 * @Description: 测试回答
 * @version: 
 * @Author: HWL
 * @Date: 2021-06-01 17:40:30
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-26 10:48:59
-->
<template>
<div class="exercise">
  <div class="content">
    <Title 
      v-show="curPage===0"
      :title="questionnaire.title" 
      :description="questionnaire.description"
      :isOnlyRead="true"
      :isPc='isPc'
    />
    <template v-for="(item, index) in questionnaire.questions">
    <!-- 题目主体 -->
    <div :id="item.questionId" class="question-wrapper" v-show="item.visible && item.page === curPage">
      <component
        :is="item.componentName"
        :item="item"
        :isOnlyRead="true"
        :key="item.questionId"
        :isPc='isPc'
      />
    </div>
    </template>
    <QuestionnaireBottom
      :isPc="isPc"
      :curPage="curPage"
      :maxPage="maxPage"
      :isLastPage="isLastPage"
      @submit="handleSubmitClick"
      @next="handleNextClick"
    />
  </div>
</div>
</template>

<script>
import Header from '@/components/common/Header'
import Title from '@/components/question/others/Title'
import Page from '@/components/question/others/Page'
import Part from '@/components/question/others/Part'
import SingleChoice from '@/components/question/SingleChoice'
import MultipleChoice from '@/components/question/MultipleChoice'
import DropDown from '@/components/question/DropDown'
import Scale from '@/components/question/Scale'
import MatrixScale from '@/components/question/MatrixScale'
import MatrixSingleChoice from '@/components/question/MatrixSingleChoice'
import MatrixMultipleChoice from '@/components/question/MatrixMultipleChoice'
import Short from '@/components/question/Short'
import Date from '@/components/question/Date'
import globalQuestionnaire from '@/class/useQuestionnaire'
import useCommonConstructEffect from '@/effects/constructEffect'

import { useRoute, useRouter } from 'vue-router'
import { ref, onUnmounted, watch } from 'vue'
import { message } from 'ant-design-vue'

import { getNowTime } from '@/utils/date.js'
import { getTerminalType } from '@/utils/userAgent.js'
import { submitAnswer } from '@/api/questionnaire'
import { getQuestionnaireById, getQuestionnaireInterrupt } from '@/api/questionnaire'
import QuestionnaireBottom from '@/components/question/QuestionnaireBottom'

/**
 * 获取问卷信息
 */
const getQuestionnaireEffect = () => {
  const route = useRoute()
  const router = useRouter()
  const id = route.params.id
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const isLastPage = globalQuestionnaire.getIsLastPage()
  const { constructExerciseQuestion } = useCommonConstructEffect()
  // 获取问卷信息
  const getQuestionnaireByAsync = async () => {
    const result = await getQuestionnaireById(id)
    console.log(result)
    if (result.status === 200 && result.data?.code === '00000' && result?.data?.data) {
      console.log('请求回来的问卷数据[访客]：', result.data.data)
      let obj = JSON.parse(JSON.stringify(result.data.data))
      globalQuestionnaire.setQuestionnaire(constructExerciseQuestion(obj))
    } else {
      message.error(' 请求答卷失败，失败原因：' + result.data.msg)
    }
  }
  getQuestionnaireByAsync()
  return {
    questionnaire,
    isLastPage,
  }
}

/**
 * 按钮点击事件
 */
const handleBtnClickEffect = (questionnaire) => {
  const router = useRouter()
  const curPage = ref(0)
  const startTime = getNowTime()
  
  watch(() => curPage.value, (cur, pre) => {
    // 查看本页后还是否存在可显示的数据
    const list = questionnaire.questions.filter(question => question.page > cur).filter(question => question.visible)
    const bool = list.length === 0 ? true : false
    globalQuestionnaire.setIsLastPage(bool)

  })

   // 答案校验
  const checkData = () => {
    const warnTips = []
    const warnQuestionIds = []
    const questions = questionnaire.questions.filter(question => question.page === curPage.value && question.visible && question.ifRequired)
    // 1、校验答案的完整性
    questions.forEach(question => {
      // 校验 单选题、多选题、下拉题、量表题
      if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE|DROP_DOWN|SCALE)$/.test(question.questionType)) {
        if (!question.answer || !question.answer.questionId || (question.answer.choices && question.answer.choices.length === 0)) {
          warnTips.push(`请填写第${question.sort + 1}题`)
          warnQuestionIds.push(question.questionId) 
        } 
        // 校验单选、多选必须填的必填填空是否填写
        else if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/.test(question.questionType)) {
          // 是否存在勾选的选项至少有一个是必填的但是没有填空的情况
          let flag = question.answer.choices.some(answerChoiceData => {
            let originChoiceData = question.choices.find(item => item.choicdId === answerChoiceData.choicdId) 
            let ifRequired = originChoiceData.ifRequired
            if (ifRequired && !answerChoiceData.fillText) return true
            return false
          })
          if (flag) {
            warnTips.push(`请填写第${question.sort + 1}题`)
            warnQuestionIds.push(question.questionId) 
          } 
        }

      }
      // 校验 矩阵量表、矩阵单选、矩阵多选
      else if (/^(MATRIX_SCALE|MATRIX_SINGLE_CHOICE|MATRIX_MULTIPLE_CHOICE)$/.test(question.questionType)) {
        if (!question.answer || !question.answer.tableCheck) {
          warnTips.push(`请填写第${question.sort + 1}题`)
          warnQuestionIds.push(question.questionId)
        }
        else {
          // 是否存在有一行全为false
          if (Object.values(question.answer.tableCheck).some(rowAnswerObj => 
            Object.values(rowAnswerObj).every(colAnswer => colAnswer === false)
          )) {
            warnTips.push(`请填写第${question.sort + 1}题`)
            warnQuestionIds.push(question.questionId)
          }
        }
      }
      // 校验 简答题、日期题
      else if (/^(SHORT|DATE)$/.test(question.questionType)) {
        if (!question.answer || !question.answer.fillText) {
          warnTips.push(`请填写第${question.sort + 1}题`)
          warnQuestionIds.push(question.questionId)
        }
      }
    })
    // 2、校验多选、矩阵多选题至少选择的选项
    questions.filter(question => /^(MULTIPLE_CHOICE|MATRIX_MULTIPLE_CHOICE)$/.test(question.questionType)).forEach(question => {
      if (question.questionType === 'MULTIPLE_CHOICE' 
      && question.minSelect 
      && question.answer 
      && question.answer.questionId
      && question.answer.choices.length < question.minSelect) {
        warnTips.push(`第${question.sort + 1}题至少选${question.minSelect}项`)
        warnQuestionIds.push(question.questionId)
      } else if (question.questionType === 'MATRIX_MULTIPLE_CHOICE' 
      && question.minSelect 
      && question.answer 
      && question.answer.tableCheck) {
        // 是否存在有一行全为false
        if (!Object.values(question.answer.tableCheck).some(rowAnswerObj => 
          Object.values(rowAnswerObj).every(colAnswer => colAnswer === false))) {
            // 进到这里，说明用户每一行都已勾选 
            Object.values(question.answer.tableCheck).forEach((rowAnswerObj, rowIndex) => {
              const trueNum = Object.values(rowAnswerObj).reduce((trueNum, colAnswer)=> {
                colAnswer ? ++trueNum : null
                return trueNum
              }, 0)
              if (trueNum < question.minSelect ) 
                warnTips.push(`第${question.sort + 1}题第${rowIndex + 1}行至少选${question.minSelect}项`)
                warnQuestionIds.push(question.questionId)
            })
          }
      }
    })
      
    return {
      warnTips: warnTips.join('\n'),
      warnQuestionIds: warnQuestionIds
    }
  }

  // 答案完整性校验
  const checkAnswer = (questionType, answer) => {
    let bool = true
    if (/^(MATRIX_SCALE|MATRIX_SINGLE_CHOICE|MATRIX_MULTIPLE_CHOICE)$/.test(questionType)) {
      // 是否存在有一行全为false
      bool = Object.values(answer.tableCheck).some(rowAnswerObj => 
        Object.values(rowAnswerObj).every(colAnswer => colAnswer === false)
      ) ? false : true
    }
    return bool
  }

  // 校验不通过处理
  const handleWarnTips = (warnTips, warnQuestionIds) => {
    message.error(warnTips)
    warnQuestionIds.forEach(questionId => {
      document.getElementById(questionId).classList.add('error')
    })
    document.getElementById(warnQuestionIds[0]).scrollIntoView()
  }

  // 下一页
  const handleNextClick = () => {
    const { warnTips, warnQuestionIds } = checkData()
    if (warnTips !== '') {
      handleWarnTips(warnTips, warnQuestionIds)
      return
    }
    let nextPageQuestion = getNextPageQuestion(curPage)
    
    // 递归查找下一页的数据，且更新curPage
    function getNextPageQuestion (curPage) {
      curPage.value += 1
      let nextPage = curPage.value
      let maxPage = Math.max(...Object.keys(questionnaire.pageMap))
      let nextPageQuestions = questionnaire.questions.filter(question => (!(/^PART$/.test(question.questionType)) && question.page === nextPage && question.visible))
      console.log('questionnaire', questionnaire)
      console.log('nextPage：', nextPage)
      console.log('nextPageQuestions', nextPageQuestions)
      if (nextPageQuestions.length === 0) {
        if (maxPage === curPage.value) { 
          return [] 
        }
        return getNextPageQuestion(curPage)
      } else {
        return nextPageQuestions
      }
    }
  }

  // 提交
  const handleSubmitClick = async () => {
    setTimeout(() => {
      const endTime = getNowTime()
      const data = {
        'questionnaireProtoId': questionnaire.id,
        'collectSource': getTerminalType(),
        'duration': Math.floor((endTime - startTime) / 1000),
        'answers': []
      }
      for (let i = 0; i < questionnaire.questions.length; i++) {
        let curQuestion = questionnaire.questions[i]
        if (!/PAGE|PART/.test(curQuestion.questionType) && curQuestion.answer && curQuestion.answer.questionId && curQuestion.visible) {
          // 非必答题，校验答案的完整性，答案完整，则保存答案，否则不保存答案
          if (!curQuestion.ifRequired) {
            checkAnswer(curQuestion.questionType, curQuestion.answer) ? data.answers.push(curQuestion.answer) : null
            continue
          }
          data.answers.push(curQuestion.answer)
        }
      }
      const { warnTips, warnQuestionIds } = checkData()
      if (warnTips !== '') {
        handleWarnTips(warnTips, warnQuestionIds)
        return
      }
      message.success('合法答卷')
    })

    async function submitAnswerEvent (data) {
      const res = await submitAnswer(data)
      if (res.status === 200 && res.data?.code === '00000') {
        router.push({
          path: '/success',
          replace: true, // 不允许后退
          query: {
            concludingRemarks: questionnaire.questionnaireProtoSetting?.concludingRemarks || '感谢您的参与！'
          }

        })
        // message.success('上传答案成功')
        
      } else {
        message.error('上传答案失败，失败原因：' + res.data.msg)
      }
    }
  }
  return {
    startTime,
    curPage,
    handleNextClick,
    handleSubmitClick,
  }
}

/**
 * 断点续答相关操作
 */
// const interruptEffect = (questionnaire, startTime) => {
//   const url = process.env.VUE_APP_BASE_URL + '/client/questionnaire-proto/interrupt'
//   const onbeforeunloadEvent = (e) => {
//     const data = {
//       'questionnaireProtoId': questionnaire.id,
//       'collectSource': getTerminalType(),
//       'duration': Math.floor((endTime - startTime) / 1000),
//       'answers': []
//     }
//     questionnaire.questions
//       .filter(question => 
//         !/(PAGE|PART)$/.test(question.questionType) 
//         && question.answer 
//         && question.answer.questionId).forEach(question => {
//           data.answers.push(question.answer)
//         })
//     const res = navigator.sendBeacon(url, data)
//     console.log('使用sendBeacon发送请求', res)
//   }
//   return { 
//     onbeforeunloadEvent 
//   }
// }

export default {
  name: 'TestExercise',
  components: { 
    Header,
    
    Title,
    Page,
    Part,
    SingleChoice,
    MultipleChoice,
    DropDown,
    Scale,
    MatrixScale,
    MatrixSingleChoice,
    MatrixMultipleChoice,
    Short,
    Date,
    QuestionnaireBottom
  },
  setup () {
    const isPc = getTerminalType() === 'PC' ? true : false
    const route = useRoute()
    const router = useRouter()
    const id = route.params.id
    const { questionnaire, isLastPage } = getQuestionnaireEffect()
    const { startTime, curPage, handleNextClick, handleSubmitClick, } = handleBtnClickEffect(questionnaire)
    // const { onbeforeunloadEvent } = interruptEffect(questionnaire, startTime)
    const maxPage = ref(Math.max(...Object.keys(questionnaire.pageMap)))
    onUnmounted(() => {
      globalQuestionnaire.clearQuestionnaire()
      globalQuestionnaire.setIsLastPage(false)
    })
    window.onbeforeunload = function () {
      const url = process.env.VUE_APP_BASE_URL + '/client/questionnaire-proto/interrupt'
      const endTime = getNowTime()
      const data = {
        'questionnaireProtoId': questionnaire.id,
        'duration': Math.floor((endTime - startTime) / 1000),
        'answers': []
      }
      questionnaire.questions
        .filter(question => 
          !/(PAGE|PART)$/.test(question.questionType) 
          && question.answer 
          && question.answer.questionId).forEach(question => {
            data.answers.push(question.answer)
          })
      let headers = {
        type: 'application/json'
      }
      let blob = new Blob([JSON.stringify(data)], headers);
      const res = navigator.sendBeacon(url, blob)
      console.log('使用sendBeacon发送请求', res)
    }
    return {
      isPc,
      curPage,
      isLastPage,
      maxPage,
      questionnaire,
      handleNextClick,
      handleSubmitClick,
    }
  }
}
</script>

<style scoped lang='less'>
.error {
  border: 1px solid red;
  margin-bottom: 10px;
}
.exercise {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: @backgroundColor;
}
.content {
  width: 1100px;
  margin: 20px auto;
  background: @backgroundColor;
  border-radius: 10px;
  padding: 0 40px;
  .bottom {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items:center;    
    .button {
      width: 76px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      color: #fff;
      background: #f59a23;
      border-radius: 8px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background-color: #f59a23d7;
      }
      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
}
</style>