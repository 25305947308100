<!--
 * @Description: 问卷预览
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-19 11:03:16
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-27 16:23:48
-->
<template>
  <Header
    :isShowPersonal="true"
    :isShowExport="true"
    @testAnswerChange="handleTestAnswerChange"
  />
  <div class="preview" v-if="!testAnswer">
    <div class="content">
      <component
        v-if="questionnaire.questions && questionnaire.questions.length > 0"
        style="margin-top: 0px"
        :is="questionnaire.questions[0].componentName"
        :item="questionnaire.questions[0]"
        :index="0"
        :isOnlyRead="true"
        :isPc="isPc"
      />
      <Title 
        v-show="curIndex===0"
        :title="questionnaire.title" 
        :description="questionnaire.description"
        :isOnlyRead="true"
        :isPc='isPc'
      />
      <div class="question-group-item" v-for="(item, index) in questionnaire.questions" :key="item.questionId" :id="item.questionId">
        <component
          v-if="index !== 0"
          :is="item.componentName"
          :item="item"
          :index="index"
          :isOnlyRead="true"
          :isPreveiw="true"
          :isPc="isPc"
        />
      </div>
      <!-- <component
        v-for="(item, index) in questionnaire.questions"
        :is="item.componentName"
        :item="item"
        :index="index"
        :isOnlyRead="true"
        :key="index"
        :isPc='isPc'
      /> -->
      <!-- <div class="buttongroup">
        <div class="button" @click="handleUpClick" v-if="curIndex!==0">上一页</div>
        <div class="button" @click="handleNextClick" v-if="curIndex!==lastPageIndex">下一页</div>
        <div class="button" @click="handleSubmitClick" v-if="curIndex==lastPageIndex">提交</div>
      </div> -->
       <div class="buttongroup">
        <div class="button">提交答卷</div>
      </div>
    </div>
  </div>
  <TestExercise v-else />
</template>

<script>
import Header from '@/components/common/Header'
import Title from '@/components/question/others/Title'
import Page from '@/components/question/others/Page'
import Part from '@/components/question/others/Part'
import SingleChoice from '@/components/question/SingleChoice'
import MultipleChoice from '@/components/question/MultipleChoice'
import DropDown from '@/components/question/DropDown'
import Scale from '@/components/question/Scale'
import MatrixScale from '@/components/question/MatrixScale'
import MatrixSingleChoice from '@/components/question/MatrixSingleChoice'
import MatrixMultipleChoice from '@/components/question/MatrixMultipleChoice'
import Short from '@/components/question/Short'
import Date from '@/components/question/Date'

import globalQuestionnaire from '@/class/useQuestionnaire'
import useCommonConstructEffect from '@/effects/constructEffect'

import { useRoute } from 'vue-router'
import { computed, reactive, watchEffect, ref, onUnmounted, onMounted, nextTick } from 'vue'
import { getQuestionnaireById } from '@/api/questionnaire'
import { deepCopy } from '@/utils/deepCopy'
import { getTerminalType } from '@/utils/userAgent.js'

import TestExercise from './testExercise'


let routeId;
/**
 * 获取问卷信息
 */
const getQuestionnaireEffect = () => {
  const route = useRoute()
  const id = routeId ? routeId : route.params.id
  routeId = id
  const originalQuestionnaire = {} // 保存一份原始的问卷数据
  const questionnaire = globalQuestionnaire.getQuestionnaire() // 保存结构化后的问卷数据
  const { constructPrewiewQuestion } = useCommonConstructEffect()
  const getQuestionnaireByAsync = async () => {
    const result = await getQuestionnaireById(id)
    if (result.status === 200 && result.data?.code === '00000' && result?.data?.data) {
      let data = deepCopy(result.data.data)
      Object.assign(originalQuestionnaire, data)
      globalQuestionnaire.setQuestionnaire(constructPrewiewQuestion(data))
    } else {
      message.error(' 请求答卷失败，失败原因：' + result.data.msg)
    }
  }

  getQuestionnaireByAsync()
  return {
    questionnaire,
    originalQuestionnaire,
  }
}

let testAnswer = ref(false)

/**
 * 预览问卷相关操作
 */
const previewEffect = () => {
  const handleTestAnswerChange = (checked) => {
    testAnswer.value = checked
    if (!checked) {
      getQuestionnaireEffect()
    }
  }
  return {
    handleTestAnswerChange,
  }
}

/**
 * 测试作答相关操作
 */
const testAnswerEffect =(questionnaire) => {
  const list = ref([])
  const curIndex = ref(0)
  const lastPageIndex = ref(0)
  const handleNextClick = () => { curIndex.value++ }
  const handleUpClick = () => { curIndex.value-- }
  // watchEffect(() => {
  //   if (questionnaire.questionnaireProtoId === undefined) return 
  //   list.value = []
  //   lastPageIndex.value = questionnaire.pageMap ? Number(Object.keys(questionnaire.pageMap).pop()) : 0
  //   let questions = questionnaire.questions || []
  //   for (let i = 0; i < questions.length; i++) {
  //     if (!/(page)/.test(questions[i].questionType.toLowerCase()) 
  //       && questions[i].page === curIndex.value) {
  //       list.value.push(questions[i])
  //     }
  //   }
  // })
  return {
    list,
    curIndex,
    lastPageIndex,
    handleNextClick,
    handleUpClick,
  }
}

export default {
  name: 'Preview',
  components: { 
    Header,
    
    Title,
    Page,
    Part,
    SingleChoice,
    MultipleChoice,
    DropDown,
    Scale,
    MatrixScale,
    MatrixSingleChoice,
    MatrixMultipleChoice,
    Short,
    Date,
    TestExercise,
  },
  setup () {
    const isPc = getTerminalType() === 'PC' ? true : false
    const { questionnaire, originalQuestionnaire } = getQuestionnaireEffect()
    const { list, curIndex, lastPageIndex, handleNextClick, handleUpClick } = testAnswerEffect(questionnaire)
    const { handleTestAnswerChange } = previewEffect()
    nextTick(() => {
          console.log(questionnaire)
    })
    onUnmounted(() => {
      globalQuestionnaire.clearQuestionnaire()
    })
    
    return {
      isPc,
      list,
      curIndex,
      lastPageIndex,
      questionnaire,
      originalQuestionnaire,
      handleNextClick,
      handleUpClick,
      handleTestAnswerChange,
      testAnswer
    }
  }
}
</script>

<style scoped lang='less'>

.preview {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: @backgroundColor;
}
.content {
  width: 1100px;
  margin: 20px auto;
  background: @backgroundColor;
  border-radius: 10px;
  padding: 0 40px;
  .buttongroup {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items:center;    
    .button {
      width: 100px;
      height: 41px;
      line-height: 41px;
      text-align: center;
      color: #fff;
      background: #00A0E9;
      border-radius: 2px;
      font-size: 16px;
      cursor: pointer;
      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
}
</style>